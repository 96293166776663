import React, { forwardRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = forwardRef(({ logo }, ref) => {
  const navigate = useNavigate();

  return (
    <div className='fixed-top' ref={ref}>
      <nav className="navbar navbar-expand-lg " style={{ padding: '30px', backgroundColor: '#ddcfcb', position: 'relative' }}>
        {Boolean(logo) &&
          <Link to="/">
            {/* {!logo
              ? <span>WOODAWASTE</span>
              : <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${logo}`} alt="LOGO" style={{ width: '200px' }} />
            } */}
            <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${logo}`} alt="LOGO" className="logo" />
          </Link>
        }

        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto text-end pt-3 pt-lg-0">
            <li className="nav-item me-lg-4" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <Link className="nav-link text-dark" to="/SectionA" onClick={() => navigate("/SectionA")}>VORES PLANKEBORDE</Link>
            </li>
            <li className="nav-item me-lg-4" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <Link className="nav-link text-dark" to="/SectionB" onClick={() => navigate("/SectionB")}>OM OS</Link>
            </li>
            <li className="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
              <Link className="nav-link text-dark" data-bs-toggle="modal" data-bs-target="#orderForm" data-bs-to="#">KONTAKT</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
});

export default Header;
