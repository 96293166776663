import React, { forwardRef } from 'react'
import fbLogo from '../assets/images/fb.png'
import twitter from '../assets/images/twitter.png'
import insta from '../assets/images/insta.png'

const Footer = forwardRef(({ socials }, ref) => {
  return (
    <>
      <footer className="fixed-bottom p-2" ref={ref}>
        <ul className="d-flex justify-content-sm-start justify-content-center align-items-center social-icons-list mb-0 p-0">
          {socials.facebook &&
            <li>
              <a href={socials.facebook}>
                <img src={fbLogo} alt="Facebook" className='social-icon' />
              </a>
            </li>
          }
          {socials.instagram &&
            <li>
              <a href={socials.instagram}>
                <img src={insta} alt="Instagram" className='social-icon' />
              </a>
            </li>
          }
          {socials.twitter &&
            <li>
              <a href={socials.twitter}>
                <img src={twitter} alt="Twitter" className='social-icon' />
              </a>
            </li>
          }
        </ul>
      </footer>
    </>
  )
});

export default Footer;