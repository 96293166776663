import React from 'react'
import { useEffect, useState } from 'react';
import naturepopup from '../assets/images/naturepopup.svg'
import recyclingpopup from '../assets/images/recyclingpopup.svg';
import bgpic from '../assets/images/bg-pic.jpg';
import pic from '../assets/images/log.png';


const SectionB = ({ topOffset, bottomOffset }) => {
  const [sectionData, setSectionData] = useState();
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/section-b?populate=*`);
        if (!response.ok) {
          console.log(`Network response was not OK`);
        }
        const { data } = await response.json();
        setSectionData(data);
      } catch (error) {
        throw new Error(`Error fetching data`, error);

      }
    }
    getData();
  }, [])
  if (!sectionData) {
    return <>Loading...</>
  }
  return (
    <section className='section-b pt-5' style={{ margin: `${topOffset}px 0px ${bottomOffset}px`, minHeight: `calc((100vh - ${topOffset}px) - ${bottomOffset}px)`, height: 0 }}>
      <div className='container-fluid position-relative'>
        <div className="row position-relative">
          <div className="image-container position-relative col-sm-4 p-0">
            <img src={naturepopup} alt="" className="sticker sticker-one" />
            <img
              src={`${process.env.REACT_APP_BACKEND_BASE_URL}${sectionData.attributes.image1.data.attributes.formats?.medium?.url ?? sectionData.attributes.image1.data.attributes.url}`}
              alt="WHY BURN WASTE WOOD WHEN IT CAN BE RECYCLED?"
              className="image"
            />
            <p className="image-text mb-0" style={{ backgroundColor: "rgba(81, 40, 0, 0.76)", cursor: 'default' }}>{sectionData.attributes.text1}</p>
          </div>

          <div className="image-container position-relative col-sm-4 p-0">
            <img
              src={`${process.env.REACT_APP_BACKEND_BASE_URL}${sectionData.attributes.image2.data.attributes.formats?.medium?.url ?? sectionData.attributes.image2.data.attributes.url}`}
              alt="ORDER A PLANK TABLE"
              className="image"
            />
            <img src={pic} className='position-absolute d-flex top-0  ' style={{ width:"100%", height:"100%", backgroundColor: "rgba(81, 40, 0, 0.2)" , objectFit:"contain", objectPosition:"center" }} alt='Woodawaste'/>
            {/* <p className="image-text mb-0 " style={{ backgroundColor: "rgba(81, 40, 0, 0.4)" }}>{sectionData.attributes.text2}</p> */}
          </div>

          <div className="image-container position-relative col-sm-4 p-0">
            <img src={recyclingpopup} alt="Sticker two" className="sticker sticker-two" />
            <img
              src={`${process.env.REACT_APP_BACKEND_BASE_URL}${sectionData.attributes.image3.data.attributes.formats?.medium?.url ?? sectionData.attributes.image3.data.attributes.url}`}
              alt="USE RECYCLED WOOD AND REDUCE CO2 EMISSIONS BY 20-50%"
              className="image"
            />
            <p className="image-text mb-0" style={{ backgroundColor: "rgba(81, 40, 0, 0.76)", cursor: 'default' }}>{sectionData.attributes.text3}</p>
          </div>
        </div>

        <div className="about row gx-5 pt-4 pb-2">
          {sectionData.attributes.aboutSection1 && (
            <div className="col-md-6" style={{ fontSize: "1rem" }} dangerouslySetInnerHTML={{ __html: sectionData.attributes.aboutSection1 }} />
          )}
          {sectionData.attributes.aboutSection2 && (
            <div className="col-md-6" style={{ fontSize: "1rem" }} dangerouslySetInnerHTML={{ __html: sectionData.attributes.aboutSection2 }} />
          )}
        </div>


        <div className="pb-5 text-center">
          <button className='button' data-bs-toggle="modal" data-bs-target="#orderForm">SEND FORESPØRGSEL</button>
        </div>
        
      </div>
    </section>
  )
}

export default SectionB;