import React from 'react'
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


const SectionC = ({ topOffset, bottomOffset }) => {
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageText, setSelectedImageText] = useState('');
  const [SectionC, setSectionC] = useState([])
  const [loading, setLoading] = useState(true)

  const params = useParams();
  const handleImageClick = (imageUrl, text) => {
    setSelectedImage(imageUrl);
    setSelectedImageText(text);
  };

  useEffect(() => {
    const getSectionAData = async (id) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/section-as/${id}?populate[tableOptions][populate]=image`);
        if (!response.ok) {
          console.log(`Network response was not OK`);
        }
        const { data } = await response.json();
        setSectionC(data);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        throw new Error(`Error fetching data`, error);
      }
    }
    if (params.id) {
      getSectionAData(params.id);
    }
  }, [params.id])

  return (
    <section className='section-c' style={{ margin: `${topOffset}px 0px ${bottomOffset}px`, minHeight: `calc((100vh - ${topOffset}px) - ${bottomOffset}px)` }}>
      <div className='container-fluid p-0 h-100 text-center'>
        <div className='row g-1 mx-0 h-100'>
          {loading
            ? <div className='d-flex justify-content-center align-items-center fw-bold'>
                <div className="spinner-border text-dark" role="status">
                </div>
              </div>
            : !SectionC.attributes?.tableOptions?.data.length
              ? <div className='d-flex justify-content-center align-items-center fw-bold'>No Image Found</div>
              : SectionC && SectionC.attributes?.tableOptions?.data?.map((item, index) => (
                  <div className="col-6 col-sm-4 col-md-3" data-bs-toggle="modal" data-bs-target="#myModal" style={{ height: "auto", aspectRatio: 1 }}
                    onClick={() =>
                      handleImageClick(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${item.attributes.image.data.attributes.formats?.medium?.url ??
                        item.attributes.image.data.attributes.url
                        }`,
                        `${item.attributes.text}`
                      )
                    }
                    key={index}
                  >
                    <img
                      src={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.attributes.image.data.attributes.formats?.medium?.url ?? item.attributes.image.data.attributes.url}`}
                      alt="Picture1"
                      className="w-100 h-100"
                      style={{ objectFit: "cover", objectPosition: "center" }}
                    />
                  </div>
                ))
          }

          {Boolean(SectionC.attributes?.tableOptions?.data.length) &&
            <div className="col-12 d-flex justify-content-center align-items-center py-5">
              <button className='button' data-bs-toggle="modal" data-bs-target="#orderForm">SEND FORESPØRGSEL</button>
            </div>
          }
        </div>
      </div>

      <div className="modal fade" id="myModal">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: "#ddcfcb" }}>
            <div className="modal-header" style={{ borderBottom: '0px' }}>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" >
              <img src={selectedImage} alt="Full Size" style={{ objectFit: "contain", height: "500px", width: "100%" }} />
              <div className='px-5 mt-2'>
                <p id="exampleModalLabel">{selectedImageText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionC;