import React, { useState,useEffect, useRef } from 'react';
import {Route,BrowserRouter as Router, Routes} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Footer from './components/Footer';
import ForesideSection from './components/ForesideSection';
import Header from './components/Header';
import SectionA from './components/SectionA';
import SectionB from './components/SectionB';
import SectionC from './components/SectionC';
import OrderForm from './components/OrderForm';

function App() {
  const navbarRef = useRef(null);
  const footerRef = useRef(null);

  const [topOffset, setTopOffset] = useState(0);
  const [bottomOffset, setBottomOffset] = useState(0);

  const [logo, setLogo]=useState();
  const [socials, setSocials]=useState({});
  const [favIcon, setFavIcon]=useState();
  const [title, setTitle]=useState();
  const [description, setDescription]=useState()

  useEffect(()=>{
    const getSiteData = async () => {
      try {
        const response=await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/site-setting?populate=*`);
        if(!response.ok){
          console.log(`Network response was not OK`);
        } else {
          const {data} = await response.json();
          setLogo(data?.attributes.logo.data?.attributes.url??'null');
          setSocials(data?.attributes.socials??'null');
          setFavIcon(data?.attributes.favicon.data?.attributes.url??'null');
          setTitle(data?.attributes.metaTitle??'null');
          setDescription(data?.attributes.metaDescription??'null');
          console.log(data,"check");
        }
      } catch (error) {
        throw new Error(`Error fetching data`,error);
      }
    }
    getSiteData();
  }, [])

  useEffect(() => {
    if (navbarRef?.current && footerRef?.current) {
      console.log(navbarRef);
      setTopOffset(navbarRef.current.offsetHeight);
      setBottomOffset(footerRef.current.offsetHeight);
    }
  }, [navbarRef?.current?.clientHeight, footerRef?.current?.clientHeight])

  return (
    <>
      <Router>
        <Helmet>
          <link rel='icon' type='image/png' href={`${process.env.REACT_APP_BACKEND_BASE_URL}${favIcon}`} />
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Header logo={logo} ref={navbarRef} />
        <Routes>
          <Route path='/' element={<ForesideSection topOffset={topOffset} bottomOffset={bottomOffset} />} />
          <Route path="/SectionA" element={<SectionA topOffset={topOffset} bottomOffset={bottomOffset} />} />
          <Route path="/SectionB" element={<SectionB topOffset={topOffset} bottomOffset={bottomOffset} />} />
          <Route path="/SectionC/:id" element={<SectionC topOffset={topOffset} bottomOffset={bottomOffset} />} />
        </Routes>
        <OrderForm />
        <Footer socials={socials} ref={footerRef} />
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
