import React from 'react';
import IMG5 from '../assets/images/IMG5.jpg'
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const OrderForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    email: '',
    detail: '',
    telephone: ''
  });
  const [headerText, setHeaderText] = useState('')

  function validateEmail(email) {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value
    }));
  };

  const handleTelephoneChange = (e) => {
    const { id, value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: numericValue
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) return toast.error('Enter a valid Email', {
      position: toast.POSITION.TOP_RIGHT
    });
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json' // 
      },
      body: JSON.stringify({ data: formData })
    };

    fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/orders`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Response from server:', data);
        toast.success("Order placed successfully", {
          position: toast.POSITION.TOP_RIGHT
        });

        setFormData({
          name: '',
          address: '',
          email: '',
          detail: '',
          telephone: '',
        });
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('An error occurred while placing the order', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  useEffect(() => {
    const getSectionAData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/order-form-detail`);
        if (!response.ok) {
          console.log(`Network response was not OK`);
        }
        const { data } = await response.json();
        setHeaderText(data);
      } catch (error) {
        throw new Error(`Error fetching data`, error);
      }
    }

    getSectionAData();
  }, [])

  return (
    <>
      <div className="modal fade" id="orderForm" tabIndex="-1" aria-hidden={true}>
        <div className="modal-dialog modal-lg ">
          <div className="modal-content text-white  " style={{ backgroundColor: "rgb(221, 207, 203)", padding: "10px" }}>
            <div className='modal-header position-relative' style={{ borderBottom: "none" }}>
              <h4 className="modal-title mb-0 text-center w-100">SEND FORESPØRGSEL</h4>
              <span className='close-btn pe-auto' data-bs-dismiss="modal" aria-hidden="true">×</span>
            </div>

            <div className="modal-body" style={{ marginBottom: "0px", paddingBottom: "0px" }}>

              <div className='container'>
                {headerText && <div className='text-dark mb-0' style={{ marginInline: "10px" }} dangerouslySetInnerHTML={{__html:headerText.attributes.headerText}}>
                </div>}
                <div className='row' style={{ margin: "10px", marginBottom: '5px', marginTop: '5px' }}>
                  <input type="text" id="name" placeholder='NAVN' value={formData.name} onChange={handleChange} />
                  <input type="text" id="telephone" placeholder='TELEFON' value={formData.telephone} onChange={handleTelephoneChange} />
                  <input type="text" id="address" placeholder='ADRESSE' value={formData.address} onChange={handleChange} />
                  <input type="email" id='email' placeholder='MAIL' value={formData.email} onChange={handleChange} />
                  <textarea
                    id="detail"
                    cols="30"
                    rows="8"
                    placeholder='BESKRIVELSE MED ØNSKET BORDSTØRRELSE'
                    value={formData.detail}
                    onChange={handleChange}
                    className='mt-0'
                  />
                </div>
                <div className='d-flex justify-content-center'>
                  <button className='button' style={{ padding: '8px 80px' }} onClick={handleSubmit}>SEND</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderForm;
