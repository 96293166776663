import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import popup_wood from '../assets/images/popup_wood.svg'

const SectionA = ({ topOffset, bottomOffset }) => {
  const [SectionA, setSectionA] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    const getSectionAData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/section-as?populate=*`);
        if (!response.ok) {
          console.log(`Network response was not OK`);
        }
        const { data } = await response.json();
        setSectionA(data);
      } catch (error) {
        throw new Error(`Error fetching data`, error);
      }
    }
    getSectionAData();
  }, [])

  return (
    <section className='section-a pt-sm-5' style={{ margin: `${topOffset}px 0px ${bottomOffset}px`, minHeight: `calc((100vh - ${topOffset}px) - ${bottomOffset}px)`, height: 0 }}>
      <div className='container-fluid h-100 text-center'>
        <div className='row h-100'>
          {SectionA && SectionA?.map((item, index) => (
            <div className='category-container col-sm-4 position-relative p-0 mt-4 mt-sm-0' onClick={()=>navigate(`/SectionC/${item.id}`)} key={index}>
              {index === 2 && <img src={popup_wood} alt="" className="sticker" />}
              <div className="image-container">
                <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.attributes.image.data.attributes.formats?.medium?.url ?? item.attributes.image.data.attributes.url}`} alt={`Category ${index+1}`} />
              </div>
              <p className='image-text mb-0'>{item.attributes.text}</p>
            </div>
          ))}

          <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "30%", minHeight: "100px" }}>
            <button className='button' data-bs-toggle="modal" data-bs-target="#orderForm">SEND FORESPØRGSEL</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionA;