import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

const ForesideSection = ({ topOffset, bottomOffset }) => {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/section-foresides?populate=*`);
        if (!response.ok) {
          console.log(`Network response was not OK`);
        }
        const { data } = await response.json();
        setImageData(data);
      } catch (error) {
        throw new Error(`Error fetching data`, error);
      }
    }
    getData();
  }, [])

  return (
    <section className='container-fluid section-foreside' style={{ margin: `${topOffset}px 0px ${bottomOffset}px`, minHeight: `calc((100vh - ${topOffset}px) - ${bottomOffset}px)` }}>
      <div className="row h-100">
        {imageData.map((item, index) => (
          <div key={index} className='position-relative col-sm-4 p-0 image-container'>
            {index % 2 === 0
              ? <>
                  <Link to='/SectionA'>
                    <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.attributes.image.data.attributes.formats?.medium?.url ?? item.attributes.image.data.attributes.url}`} alt={`Image ${index + 1}`} style={{ height: `calc(((100vh - ${topOffset}px) - ${bottomOffset}px) / 3)`, minHeight: "160px" }} />
                    {index === 4 &&
                      <div className="image-text" style={{ backgroundColor: "rgba(255, 255, 255, 0.3)", fontSize: "24px", color: "#000" }}>
                        <p className="mb-0" style={{ fontWeight: "400" }}>{item.attributes.text}</p>
                      </div>
                    }
                  </Link>
                </>
              : <>
                  <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}${item.attributes.image.data.attributes.formats?.medium?.url ?? item.attributes.image.data.attributes.url}`} alt={`Image ${index + 1}`} style={{ height: `calc(((100vh - ${topOffset}px) - ${bottomOffset}px) / 3)`, minHeight: "160px" }} />
                  <Link to="/SectionB">
                    <div className="image-text" style={{ backgroundColor: "rgba(81, 40, 0, 0.76)" }}>
                      {item.attributes.text}
                    </div>
                  </Link>
                </>
            }
          </div>
        ))}
      </div>
    </section>
  )
}

export default ForesideSection;